<template>
    <div class="avatar-cropper">
        <input :ref="`${id}-input`" type="file" name="image" class="hidden" :accept="mimes" @change="setImage" />

        <section class="cropper-area" v-if="src">
            <div class="img-cropper overflow-hidden">
                <vue-cropper
                    v-show="!cropImg"
                    :ref="id"
                    :src="imgSrc"
                    :view-mode="3"
                    :aspectRatio="aspectRatio"
                    :minCropBoxHeight="minCropBoxHeight"
                    :minCanvasWidth="minCanvasWidth"
                    :minCanvasHeight="minCanvasHeight"
                />
                <img v-show="cropImg" :src="cropImg" alt="#" class="w-full h-full" />
            </div>

            <div class="actions flex gap-2 mt-4">
                <a href="#" role="button" class="btn btn-primary" @click.prevent="showFileChooser"> Выбрать </a>
                <a href="#" role="button" class="btn btn-primary" @click.prevent="move"> Сдвиг изображения </a>
                <a href="#" role="button" class="btn btn-primary" @click.prevent="drag"> Сдвиг области </a>
                <a href="#" role="button" class="btn btn-danger" @click.prevent="reset"> Сброс </a>
                <a href="#" role="button" class="btn btn-primary" @click.prevent="cropImage"> Обрезка </a>
            </div>

            <!--<textarea v-model="data" />-->
        </section>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { helper as $h } from '@/utils/helper';
const defaultImage = '/images/default-trainer.jpg';

export default {
    name: 'CustomCropper',
    components: {
        VueCropper,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        src: {
            type: String,
            default: defaultImage,
        },
        mimes: {
            type: String,
            default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
        },
        aspectRatio: {
            type: Number,
            default: 640 / 480,
        },
        minCropBoxHeight: {
            type: Number,
            default: 244,
        },
        minCanvasWidth: {
            type: Number,
            default: 640,
        },
        minCanvasHeight: {
            type: Number,
            default: 640,
        },
    },
    data() {
        return {
            imgSrc: '',
            cropImg: '',
            data: null,
        };
    },
    computed: {
        getRef() {
            return this.$refs[this.id];
        },
    },
    beforeMount() {
        this.imgSrc = this.src ? this.src : defaultImage;
    },
    watch: {
        src(val) {
            if (val) this.imgSrc = val;
        },
    },
    methods: {
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            const canvasData = this.getRef.getCroppedCanvas();
            this.cropImg = canvasData.toDataURL();
            canvasData.toBlob((blob) => this.blobCallback(blob), this.mimes, 0.9);
            this.getRef.clear();
            // this.getRef.replace(require('./assets/image1.jpg'));
        },
        blobCallback(blob) {
            const file = $h.blobToFile(blob);
            const url = URL.createObjectURL(blob);
            this.$emit('on-crop', { file, preview: url });
        },
        flipX() {
            const dom = this.$refs[`${this.id}-flipX`];
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute('data-scale', scale);
        },
        flipY() {
            const dom = this.$refs[`${this.id}-flipY`];
            let scale = dom.getAttribute('data-scale');
            scale = scale ? -scale : -1;
            this.getRef.scaleY(scale);
            dom.setAttribute('data-scale', scale);
        },
        getCropBoxData() {
            this.data = JSON.stringify(this.getRef.getCropBoxData(), null, 4);
        },
        getData() {
            this.data = JSON.stringify(this.getRef.getData(), null, 4);
        },
        move(offsetX, offsetY) {
            this.getRef.setDragMode('move');
        },
        drag(offsetX, offsetY) {
            this.getRef.setDragMode('drag');
        },
        reset() {
            this.getRef.reset();
        },
        rotate(deg) {
            this.getRef.rotate(deg);
        },
        setCropBoxData() {
            if (!this.data) return;
            this.getRef.setCropBoxData(JSON.parse(this.data));
        },
        setData() {
            if (!this.data) return;
            this.getRef.setData(JSON.parse(this.data));
        },
        setImage(e) {
            const file = e.target.files[0];
            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.getRef.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Sorry, FileReader API not supported');
            }
        },
        showFileChooser() {
            this.cropImg = '';
            this.$refs[`${this.id}-input`].click();
        },
        zoom(percent) {
            this.getRef.relativeZoom(percent);
        },
    },
};
</script>

<style scoped lang="scss">
.avatar-cropper {
    .img-cropper {
        & > div {
            height: 100%;
        }
    }

    .cropper-container {
        height: 100% !important;
    }
}
</style>
